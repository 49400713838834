<template>
    <main>
        <div class="title-head">
            <div class="about-us">ABOUT US</div>
            <div class="title">
                <img src="../assets/mark.png">
                <span class="about">关于我们</span>
                <img src="../assets/mark.png">
            </div>
        </div>

        <div class="content">
            <div class="left">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="left-item">
                            <img src="../assets/experts.png" alt="experts">
                            <div class="left-item-title">行业大咖</div>
                            <div class="left-item-second-title">INDUSTRY EXPERTS</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="left-item">
                            <img src="../assets/edu.png" alt="experts">
                            <div class="left-item-title">育人为本</div>
                            <div class="left-item-second-title">EDUCATION ORIENTED</div>
                        </div>
                    </el-col>
                </el-row>

                <el-divider />
                <div class="vertical"></div>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="left-item">
                            <img src="../assets/tech.png" alt="experts">
                            <div class="left-item-title">技术领先</div>
                            <div class="left-item-second-title">LEADING TECHNOLOGY</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="left-item">
                            <img src="../assets/emp.png" alt="experts">
                            <div class="left-item-title">推荐就业</div>
                            <div class="left-item-second-title">RECOMMENDED EMPLOYMENT</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="right">
                <div class="intro">公司简介</div>

                <div class="right-content">
                    <div>幸云教育属于幸云（南京）信息科技有限责任公司旗下教育品牌，专注于培养国家科技新基建人才。包括云计算、大数据、人工智能、工业互联网、网络安全专业等。</div>

                    <div class="mt-1">
                        除了专业知识的传授，我们更注重企业项目实战结合，目前已有千余毕业学员任职于阿里，腾讯，百度，字节等大型生态企业核心岗位。深入研究课程产品的人物画像，使用场景、企业实践，从课件、教学教法、学员管理、学员就业服务上真正符合用匠心教育成就核心竞争力。
                    </div>

                    <div class="mt-1">致力于成为引领未来的卓越教育平台，肩负起培养科技英才的崇高使命，让优质教育服务惠及每一个渴望知识的灵魂。</div>
                </div>

                <div class="mt-1 data">
                    <div>
                        <div><span class="red-number">10</span><span class="red-plus">+</span></div>
                        <div class="dir">专业方向</div>
                    </div>

                    <div>
                        <div><span class="red-number">20</span><span class="red-plus">+</span></div>
                        <div class="dir">行业大咖讲师</div>
                    </div>

                    <div>
                        <div><span class="red-number">1000</span><span class="red-plus">+</span></div>
                        <div class="dir">优秀毕业生进入大厂</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
.data {
    display: flex;
    justify-content: space-around;
}

.red-number {
    color: red;
    font-weight: bold;
    font-size: 2rem;
}

.red-plus {
    color: red;
    font-weight: bold;
    font-size: 1rem;
}

.dir {
    font-size: 0.75rem;
    color: #004BE4;
}

.intro {
    text-align: right;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
}

.content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.vertical {
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 50%;
    border-left: 1px solid white;
}

.el-row {
    margin-bottom: 20px;
}

.el-row:last-child {
    margin-bottom: 0;
}

.left {
    position: relative;
    background-color: #004BE4;
    border-radius: 1rem;
    padding: 1rem;
}

.left-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-item>img {
    height: 5rem;
    width: 5rem;
}

.left-item-title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 1rem;
}

.left-item-second-title {
    font-size: 0.6rem;
    margin-top: 0.3rem;
}

.right {
    padding: 1rem;
    background-color: #F5F7FA;
    border-radius: 1rem;
    width: 40rem;
    margin-top: 2rem;
    color: black;
}

.right-content {
    margin-top: 1rem;
    text-align: left;
    text-indent: 2rem;
}

main {
    padding: 2rem 0;
    background-color: white;
}

.title-head {
    position: relative;
    height: 10rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: -2rem;
    padding-top: 4rem;
}

.title {
    position: absolute;
    padding-top: 4rem;
    left: 0;
    right: 0;
    top: 3.5rem;
    z-index: 1;
    font-size: 1.8rem;
    font-weight: bold;
    color: black;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
}

.about-us {
    font-size: 4.4rem;
    color: #EEF1F6;
    font-weight: bold;
}

.about {
    margin: 0 1rem;
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>