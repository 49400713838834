<template>
    <div class="menu_container">
        <div class="menu">
            <img class="logo" src="../assets/logo.png" alt="logo">
            <nav>
                <router-link to="/">首页</router-link> |
                <router-link to="/course">课程</router-link> |
                <router-link to="/enroll">报名</router-link> |
                <router-link to="/about">关于</router-link>
            </nav>
        </div>
    </div>
</template>

<style scoped>
.menu_container {
    margin-top: 3rem;
    background-color: white;
}

.menu {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 65rem;
    margin: auto;
}

.logo {
    height: 3rem;
}

nav a {
    font-size: 1.1rem;
    color: black;
    text-decoration-line: none;
    font-weight: bold;
}

nav a.router-link-exact-active {
    color: #42b983;
}


@media screen and (width < 65rem) {
    .menu {
        justify-content: left;
        gap: 1rem;
    }
}
</style>