import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import EnrollView from '../views/EnrollView.vue'
import CourseListView from '../views/CourseListView.vue'
import CourseView from '../views/CourseView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: EnrollView
  },
  {
    path: '/course',
    name: 'courses',
    component: CourseListView
  },
  {
    path: '/course/:id',
    name: 'course',
    component: CourseView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
