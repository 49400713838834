<template>
    <main>
        <nav>
            <router-link to="/">首页</router-link> |
            <router-link to="/course">课程</router-link> |
            <router-link to="/enroll">报名</router-link> |
            <router-link to="/about">关于</router-link>
        </nav>
    </main>
</template>

<style scoped>
main {
    background-color: white;
    color: black;
    padding: 2rem 0;
}

.content {
    max-width: 65rem;
    margin: auto;
}

dl {
    text-align: left;
}

dt {
    font-size: 1.2rem;
    font-weight: bold;
}

a {
    text-decoration-line: none;
    color: black;
}

a.router-link-exact-active {
    color: #42b983;
}
</style>