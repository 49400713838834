<template>
    <footer>
        <div>
            © 幸云 <span>{{ date.getFullYear() }}</span>
            ❤️ All rights
            reserved. &nbsp; <a rel="noopener" href="https://beian.miit.gov.cn" target="_blank">
                <span>苏ICP备2024060136号</span>
            </a>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue';

const date = ref(new Date());
</script>

<style scoped>
footer {
    background-color: #2D3E50;
    color: white;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.8rem 0;
    height: 3rem;
    z-index: 999;
}

footer a {
    color: rgb(155, 155, 155);
    font-size: 0.8rem;
}
</style>