<template>
    <ul>
        <li>地址：南京市江宁区科建路29号03幢A2单元302室（江宁高新园）</li>
        <li class="mt-1">电话：025-58608566</li>
        <li class="mt-1">座机：025-58608566</li>
    </ul>
</template>

<style scoped>
ul {
    list-style-type: none;
    text-align: left;
}
</style>