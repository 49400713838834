import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'

import TopBar from './components/TopBar.vue';
import TopMenu from './components/TopMenu.vue';
import Footer from './components/Footer.vue';
import Links from '@/components/Links.vue';

const app = createApp(App);

app.component("TopBar", TopBar);
app.component("TopMenu", TopMenu);
app.component("Footer", Footer);
app.component("Links", Links);

app.use(ElementPlus, { zIndex: 100 })
    .use(router)
    .use(createPinia())
    .mount('#app')
