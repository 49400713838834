<template>
    <div class="container">
        <el-form class="eform" action="" method="post" size="large" label-position="top">
            <el-form-item>
                <el-input v-model="contact.name" placeholder="姓名" />
            </el-form-item>

            <el-form-item>
                <el-input v-model="contact.phone" placeholder="手机" />
            </el-form-item>

            <el-form-item>
                <el-input v-model="contact.content" :rows="5" type="textarea" placeholder="咨询内容"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click.prevent="onSubmit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const contact = ref({
    name: ""
});

function onSubmit() { }
</script>

<style scoped>
.container {
    max-width: 65rem;
    border: 0.1rem solid #8f9391;
    padding: 1rem;
    background-color: black;
    color: white;
}

.el-form-item>* {
    color: white;
}

.eform>:last-child {
    margin-bottom: 0;
}
</style>