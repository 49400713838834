<template>
    <div class="relative">
        <div class="absolute">
            <div><img class="bg" src="../assets/bg-courses.png" alt="bg"></div>
        </div>

        <main>
            <div class="title-head">
                <div class="envs">ENVIRONMENT</div>
                <div class="title">
                    <img src="../assets/mark.png">
                    <span class="env">校区环境</span>
                    <img src="../assets/mark.png">
                </div>
            </div>

            <div class="flex flex-wrap justify-center gap-1">
                <div class="card blue">
                    <img class="img" src="../assets/sign.jpg" alt="sign">
                    <div class="card-title">标牌</div>
                    <img class="polygon" src="../assets/polygon.png" alt="">
                </div>

                <div class="card light-blue mt-3">
                    <img class="img" src="../assets/front.jpg" alt="sign">
                    <div class="card-title">前台</div>
                    <img class="polygon" src="../assets/polygon-1.png" alt="">
                </div>

                <div class="card yellow">
                    <img class="img" src="../assets/room.jpg" alt="sign">
                    <div class="card-title">教室</div>
                    <img class="polygon" src="../assets/polygon-2.png" alt="">
                </div>

                <div class="card purple mt-3">
                    <img class="img" src="../assets/doorway.jpg" alt="sign">
                    <div class="card-title">走廊</div>
                    <img class="polygon" src="../assets/polygon-3.png" alt="">
                </div>
            </div>
        </main>
    </div>
</template>

<style scoped>
.card {
    position: relative;
    border-radius: 0.6rem;
    height: 15rem;
    width: 10rem;
    text-align: center;
    padding: 1rem;
    overflow: hidden;
}

.img {
    height: 12rem;
    width: 100%;
    object-fit: cover;
    overflow: hidden;

}

.polygon {
    position: absolute;
    width: 3.5rem;
    right: -0.2rem;
    bottom: -2.4rem;
}

.card-title {
    font-weight: bold;
}

.blue {
    background-color: #2A6BED;
}

.light-blue {
    background-color: #31B0FF;
}

.yellow {
    background-color: #F79702;
}

.purple {
    background-color: #7970FF;
}

main {
    padding: 4rem 0;
    background-color: #f5f7fa77;
    position: relative;
    width: 100%;
    top: 2rem;
    z-index: 3;
}

.bg {
    object-fit: contain;
    width: 100%;
    z-index: -1;
}

.title-head {
    position: relative;
    height: 8rem;
    margin-top: -3rem;
}

.title {
    position: absolute;
    left: 0;
    right: 0;
    top: 1rem;
    z-index: 1;
    font-size: 1.8rem;
    font-weight: bold;
    color: black;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
}

.envs {
    font-size: 3.5rem;
    color: #EEF1F6;
    font-weight: bold;
}

.env {
    margin: 0 1rem;
}
</style>