<template>
    <div class="bar">
        <nav>
            <div class="left">
                <div class="logo"><img src="../assets/logo-t.png" alt="logo"></div>
                <a class="home" href="/">网站首页</a>
            </div>
            <div class="text"><img src="../assets/phone.png" alt="phone"> <span>025-58608566</span></div>
        </nav>
    </div>
</template>

<style scoped>
.bar {
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background-color: #EEF1F6;
    color: black;
    z-index: 999;
}

nav {
    display: flex;
    max-width: 65rem;
    margin: auto;
    justify-content: space-between;
}

.left {
    display: flex;
    align-items: center;
    line-height: 3rem;
}

.left>a {
    display: block;
}

.logo {
    height: 3rem;
    background-color: #0A51E3;
}

.logo>img {
    height: 100%;
}

.home {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.4rem;
    margin-left: 5rem;
}

.home:visited {
    color: black;
}

.text {
    font-size: 1.2rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
    line-height: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
}

.text>img {
    height: 1.2rem;
    margin-right: 0.3rem;
}

.seperator {
    margin: 0 1rem;
    border: 1px solid #203143;
}

@media screen and (width < 36rem) {
    .left>a {
        display: none;
    }
}
</style>