<template>
    <div class="bottom">
        <div class="black relative">
            <div class="bottom-btc"><img src="../assets/bottom-btn.png" alt="btn"></div>

            <div class="mt-1 flex flex-wrap justify-around gap-2 align-items-center">
                <div class="text-left">
                    <div class="blue-title">Lucky Cloud</div>
                    <div class="blue-title">Education</div>

                    <div class="white-title">Homepage</div>
                    <div class="mt-1">让匠心教育成就核心竞争力！</div>
                </div>

                <div>
                    <div>幸云信息</div>
                    <div class="mt"><img class="qrcode" src="../assets/qrcode.jpg" alt="qrcode"></div>
                    <div class="mt small-text">扫一扫 关注我们</div>
                </div>

                <div>
                    <div><img class="logow" src="../assets/logo-w.png" alt="logo"></div>
                    <div class="mt relative text-center">
                        <img src="../assets/dialog.png" alt="dialog">
                        <div class="tel">联系电话</div>
                    </div>
                    <div class="phone">025-58608566</div>
                    <div class="small-text mt">南京市</div>
                    <div class="small-text mt">江宁区科建路29号03幢A2单元302室</div>
                </div>
            </div>
        </div>

        <div class="footer flex flex-wrap justify-center">
            <div>
                © 幸云 <span>{{ date.getFullYear() }}</span>
                ❤️ All rights
                reserved. &nbsp;
            </div>

            <div>
                <a class="icp" rel="noopener" href="https://beian.miit.gov.cn" target="_blank">
                    <span>苏ICP备2024060136号</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const date = ref(new Date());
</script>

<style scoped>
.tel {
    position: absolute;
    top: 0.3rem;
    width: 100%;
}

.logow {
    height: 3rem;
}

.bottom {
    z-index: 99;
    margin-top: 2rem;
    position: relative;
}

.mt {
    margin-top: 0.5rem;
}

.qrcode {
    width: 6rem;
}

.phone {
    font-size: 1.2rem;
}

.small-text {
    font-size: 0.8rem;
}

.black {
    background-color: #1C1E1D;
    padding: 2rem;
}

.bottom-btc {
    height: 5rem;
    position: absolute;
    top: -3.12rem;
    left: 0;
    right: 0;
}

.bottom-btc>img {
    height: 100%;
}

.blue-title {
    color: #004BE4;
    font-size: 1.6rem;
    font-weight: bold;
}

.white-title {
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
}

.footer {
    background-color: #004BE4;
    color: white;
    padding: 0.4rem 0;
}

.icp {
    color: white;
    font-weight: bold;
}
</style>